import React from "react";

import "./is4.scss";
import { Link } from "gatsby";

export default () => {
  return (
    <div className="is4 container">
      <div className="is4-left">
        <h5>
          Looking for <br />a <span>JOB</span>{" "}
        </h5>
      </div>
      <div className="is4-right">
        <div className="is4-img-container">
          <p>Medical Jobs</p>
          <Link to="/candidates">CANDIDATES</Link>
        </div>
      </div>
    </div>
  );
};
