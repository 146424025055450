import { Link } from "gatsby";
import React from "react";
import "./hero.scss";

export default () => {
  return (
    <div className="hero">
      <div className="hero-content">
        <h5>Welcome to</h5>
        <h1>Premise Healthcare</h1>
        <p>Your Elite search partner</p>
        <div className="hero-btn">
          <Link to="/about-us">Know More</Link>
          <Link to="/contact-us">Contact Us</Link>
        </div>
      </div>
    </div>
  );
};
