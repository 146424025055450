import React from "react";
import Hero from "../components/pages/index/hero/hero";
import Is1 from "../components/pages/index/is1/is1";
import Is2 from "../components/pages/index/is2/is2";
import Is3 from "../components/pages/index/is3/is3";
import Is4 from "../components/pages/index/is4/is4";
import Is5 from "../components/pages/index/is5/is5";

import Head from "../components/head";

const IndexPage = () => (
  <>
    <Head dis="We source the best professionals for you. As a laid out and system endorsed healthcare enrollment organization, we offer both temporary and permanent staffing solutions." />
    <Hero />
    <Is1 />
    <Is2 />
    <Is3 />
    <Is4 />
    <Is5 />
  </>
);

export default IndexPage;
