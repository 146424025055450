import React from "react";
import "./is3.scss";

import { Link } from "gatsby";


export default () => {
  return (
    <div className="is3">
      <div className="container">
        <div className="is3-inner">
          <p>Book an appointment with one of our specialists</p>

          <Link to="/contact-us">BOOK NOW</Link>
        </div>
      </div>
    </div>
  );
};
