import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import "./is1.scss";

export default () => {
  const data = useStaticQuery(graphql`
    query is1 {
      file(relativePath: { eq: "components/pages/index/is1/clients.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  console.log(data);
  return (
    <div className="is1">
      <div className="is1-main">
        <div className="is1-left">
          <h3>Premise Healthcare. We source the best professionals for you</h3>
          <p>
            As a laid out and system endorsed healthcare enrollment
            organization, we offer both temporary and permanent staffing
            solutions.
          </p>
          <p>
            Aces in choosing the right great contender for the right position,
            we matchmake clients and competitors by continuously putting
            individuals first. We discuss straightforwardly with our clients and
            work intimately with them to comprehend their particular staffing
            needs
          </p>
          <p>
            Our reputation is built on trust: we're good at what we do, and we
            get results. More than that, we will always have time for our
            clients. Not only do we provide an individualised service, we
            harness the latest technology to streamline the recruitment process
            for you.
          </p>
        </div>
        <div className="is1-right">
          <div>
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </div>
  );
};
